import React, {Component} from 'react';
import {withTranslate} from 'react-redux-multilingual'
import './footer.scss';
import Copyright from "../copyright/copyright";
import ContactForm from "../contact-form/contact-form";


class FooterOne extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {translate} = this.props;
        return (
            <footer id="footer" className="site-footer">
                {/*Section #1*/}
                <div className="section-1 text-center">
                    <div className="container">
                        <h3 className="text-uppercase">GET IN TOUCH</h3>
                        <h2 className="text-uppercase">LET'S GET YOU WHAT YOU NEED</h2>
                    </div>
                </div>

                {/*Section #2*/}
                <div className="section-2 text-center">
                    <div className="container">
                        <h4 style={{'font-size':'1.4rem'}}>London | New York | Tokyo | Guangzhou | Rotterdam | Abu Dhabi | Istanbul | Brussels | Johannesburg</h4>
                    </div>
                </div>

                {/*Section 2*/}
                <div className="section-3">
                    <div className="container">
                        <div className="row">                            
                            <div className="col-lg-12 col-md-12">
                                <div className="email-container">
                                    <span className="icon-list-icon email-icon">
                                        <i className="fa fa-envelope"></i>
                                    </span>
                                    <span className="icon-list-text">info@dsduniversal.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Section #2*/}
                {/*Section 3*/}
                <div className="section-4">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-sm-12">
                                <Copyright title="D2D Universal"/>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


export default withTranslate(FooterOne);
