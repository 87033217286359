import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
// Import custom components
import SliderHome from "./common/slider/slider";
import OverviewFluidSection from "./sections/overview-fluid/overview-fluid";
import ImageBox from "./common/image-box/image-box";


class Home2 extends Component {
    render() {
        return (
            <div className={`home-page-wrap`}>
                <Helmet>
                    <title>D2D Universal | Commodity Trading</title>
                    <meta name="description" content="D2D Universal - Extensive experience across multiple industries"/>
                </Helmet>
                <div id="home" className="pt-0">
                    <SliderHome/>
                </div>
                <div id="overview"  className="padding-0">
                    <div className="categories-banner">
                        <div className="container-fluid">
                            <div className="row no-gutters">
                                <div className="col-xl-4 col-md-6">
                                    <ImageBox
                                        subTitle=""
                                        title="Risk Analysis"
                                        bgImage={`${process.env.PUBLIC_URL}/assets/images/logistics/risk-analysis.jpg`}
                                    >
                                    </ImageBox>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <ImageBox
                                        subTitle=""
                                        title="Risk Advice"
                                        bgImage={`${process.env.PUBLIC_URL}/assets/imagewebp/home-3/amenities.webp`}
                                    >
                                    </ImageBox>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <ImageBox
                                        subTitle=""
                                        title="Risk Management"
                                        bgImage={`${process.env.PUBLIC_URL}/assets/images/logistics/risk-management.jpg`}
                                    >
                                    </ImageBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Home2;