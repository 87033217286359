import React, {Component} from 'react';
import Slider from "react-slick";
import './slider.scss';
class SliderHome extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div className={'slider-wrap position-relative'}>
                <Slider {...settings}>
                    <div className="slider-item position-relative">
                        <div className="slider-image" style={{backgroundImage: 'url(/assets/images/logistics/plane.jpg)'}}>
                        </div>
                        <div className="container">
                            <div className="slider-content">
                                <div className="text-center w-100">
                                    <h2 className="text-white text-black-shadow">Your Partner For <br/>The Most Effective Global Sourcing </h2>
                                    <h5 className="text-white mt-5">Managing risks for a flawless operation.</h5>
                                </div>
                                {/* <div className="slider-footer text-left">
                                    <h6 className="text-white">opening hours</h6>
                                    <p className="text-white">Mon - Fri: 10am - 7pm   /   Sat: 10am - 5pm   /   Sun: 1pm - 5pm</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="slider-item position-relative">
                        <div className="slider-image" style={{backgroundImage: 'url(/assets/images/logistics/ship1.jpg)'}}>
                        </div>
                        <div className="container">
                            <div className="slider-content">
                                <div className="text-center w-100">
                                    <h2 className="text-white text-black-shadow">Extensive Experience <br/>Across Multiple Industries </h2>
                                    <h5 className="text-white mt-5">Scouring the globe to bring you exactly what you need.</h5>
                                </div>
                                {/* <div className="slider-footer text-left">
                                    <h6 className="text-white">opening hours</h6>
                                    <p className="text-white">Mon - Fri: 10am - 7pm   /   Sat: 10am - 5pm   /   Sun: 1pm - 5pm</p>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="slider-item position-relative">
                        <div className="slider-image" style={{backgroundImage: 'url(/assets/images/logistics/ship2.jpg)'}}>
                        </div>
                        <div className="container">
                            <div className="slider-content">
                                <div className="text-center w-100">
                                    <h2 className="text-white text-black-shadow">Supporting Businesses <br/> with greater access to global markets </h2>
                                    <h5 className="text-white mt-5">Your one point of call for anything and everything.</h5>
                                </div>
                                {/* <div className="slider-footer text-left">
                                    <h6 className="text-white">opening hours</h6>
                                    <p className="text-white">Mon - Fri: 10am - 7pm   /   Sat: 10am - 5pm   /   Sun: 1pm - 5pm</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        )
    }
}

export default SliderHome