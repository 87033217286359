import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import {ScrollContext} from 'react-router-scroll-4';
import {IntlProvider} from 'react-redux-multilingual'
import './index.scss';
import 'animate.css/animate.min.css';

// Import custom components
import store from './store';
import translations from './constants/translations'

// Layouts
import Home2 from "./components/home-2";
// Pages
import PageNotFound from './components/404';

// Features
import Layout from './app'
import AboutPage from "./components/pages/about/about";
import ContactPage from "./components/pages/contact/contact";
import {getAllData} from "./actions";


class Root extends React.Component {

    render() {
        // store.dispatch(getAllData());
        return (
            <Provider store={store}>
                <IntlProvider translations={translations} locale='en'>
                    <BrowserRouter basename={'/'}>
                        <ScrollContext>
                            <Switch>
                                <Layout>
                                    <Route exact to={`${process.env.PUBLIC_URL}/`} component={Home2}/>
                                    <HashLink smooth to={`${process.env.PUBLIC_URL}/#overview`} />
                                    <HashLink smooth to={`${process.env.PUBLIC_URL}/#footer`} />
                                </Layout>
                            </Switch>
                        </ScrollContext>
                    </BrowserRouter>
                </IntlProvider>
            </Provider>
        );
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));


